import React from 'react';
import Experience from "../components/Experience";

function ExperiencePage() {
    return (
        <>
            <div className='p-8'></div>
            <Experience></Experience>
        </>
    )
}

export default ExperiencePage
