import React from 'react'
import Button from './Button'

function Education() {
    return (
        <>
            <section id='education'>
                {/* Title */}
                <div className='flex flex-col items-center justify-center bg-customblue-darkest'>
                    <h1 className='text-customblue-lightest text-3xl md:text-5xl font-bold py-10'>My Education</h1>
                </div>

                <div className='p-4 flex flex-col justify-center items-center'>
                    <div className='max-w-7xl'>
                        {/* BYU */}
                        <div className='grid grid-rows-2 grid-cols-1 md:grid-rows-1 md:grid-cols-2 justify-center items-center'>
                            <div className='flex flex-col items-center justify-center p-5'>
                                <div>
                                    <h2 className='text-customblue-main text-lg md:text-xl'>The heart of the Silicon Slopes</h2>
                                    <h1 className='text-customblue-darkest text-3xl md:text-5xl font-bold'>Brigham Young University</h1>
                                    <p className='text-customblue-darkest max-w-2xl mt-4 text-base md:text-lg mb-3'>I am a Computer Science student at BYU minoring in business. I plan on graduating in spring of 2024.</p>
                                    <Button text='Learn more' to='https://cs.byu.edu/program-overview/' target='_blank' />
                                </div>
                            </div>
                            <div className='flex row-start-1 md:col-start-2 justify-center items-center'>
                                <img src='/images/byu.png' alt="BYU"></img>
                            </div>
                        </div>

                        {/* C++ */}
                        <div className='grid grid-cols-1 grid-rows-2 md:grid-cols-2 md:grid-rows-1 justify-center items-center'>
                            <div className='flex justify-center items-center'>
                                <img src='/images/cs_class.png' alt="C++ Programming Language" className=''></img>
                            </div>
                            <div className='flex flex-col items-center justify-center p-5'>
                                <div>
                                    <h2 className='text-customblue-main text-lg md:text-xl'>Data Structures, Algorithms, Discrete Mathamatics and more</h2>
                                    <h1 className='text-customblue-darkest text-3xl md:text-5xl font-bold'>C++ Programming Language</h1>
                                    <p className='text-customblue-darkest max-w-2xl mt-4 text-base md:text-lg'>We have had an extensive amount of experience with C++ building many of the common data structures and algorithms, interpreters, lexers, parsers, executers, and other C++ software to accomplish many different tasks.
                                        <br></br><br></br>We’ve also had an extensive amount of experience with IDEs such as Visual Studio and CLion. I’ve become experienced with debugging practices as well as build tools. I also have extensive experience with version control and all aspects of GitHub.</p>
                                </div>
                            </div>
                        </div>

                        {/* Full Stack Development */}
                        <div className='grid grid-rows-2 grid-cols-1 md:grid-rows-1 md:grid-cols-2 justify-center items-center'>
                            <div className='flex flex-col items-center justify-center p-5'>
                                <div>
                                    <h2 className='text-customblue-main text-lg md:text-xl'>MEVN Stack</h2>
                                    <h1 className='text-customblue-darkest text-3xl md:text-5xl font-bold'>Full Stack Development</h1>
                                    <p className='text-customblue-darkest max-w-2xl mt-4 text-base md:text-lg'>In my Full Stack Development class, we’ve learned it all. Everything from Vue.js in the front end and authentication, databases, and more for the backend.
                                        <br></br><br></br>I’ve built many projects in the class and it gave me opportunities to implement my knowledge of other systems and technologies better such as React, Firebase, SendGrid, Twillio, Firebase authentication and more. I was able to learn many different technologies and it was one of my favorite classes.</p>
                                </div>
                            </div>
                            <div className='flex row-start-1 md:col-start-2 justify-center items-center'>
                                <img src='/images/full_stack.png' alt="BYU"></img>
                            </div>
                        </div>

                        {/* Sandbox */}
                        <div className='grid grid-cols-1 grid-rows-2 md:grid-cols-2 md:grid-rows-1 justify-center items-center'>
                            <div className='flex justify-center items-center'>
                                <img src='/images/creators.png' alt="C++ Programming Language" className=''></img>
                            </div>
                            <div className='flex flex-col items-center justify-center p-5'>
                                <div className=''>
                                    <h2 className='text-customblue-main text-lg md:text-xl'>COMING SOON!!!</h2>
                                    <h1 className='text-customblue-darkest text-3xl md:text-5xl font-bold'>Sandbox at BYU</h1>
                                    <p className='text-customblue-darkest max-w-2xl mt-4 text-base md:text-lg mb-3'>In November 2021 I participated in the Sandbox Hackathon at BYU hosted by Creators at BYU. We came up with an idea, designed prototypes, and built an app in 36 hours. I was accepted to the Sandbox cohort for 2022-2023. Sandbox is designed to help students build the next wave of new tech companies. I will be the software engineer for my team.</p>
                                    <Button text='Learn more' to='https://creators.byu.edu/sandbox' target='_blank' />
                                </div>
                            </div>
                        </div>

                        {/* Levinthal Fellows */}
                        <div className='grid grid-rows-2 grid-cols-1 md:grid-rows-1 md:grid-cols-2 justify-center items-center'>
                            <div className='flex flex-col items-center justify-center p-5'>
                                <div>
                                    <h2 className='text-customblue-main text-lg md:text-xl'>Learning From The Best</h2>
                                    <h1 className='text-customblue-darkest text-3xl md:text-5xl font-bold'>Levinthal Fellows</h1>
                                    <p className='text-customblue-darkest max-w-2xl mt-4 text-base md:text-lg'>I have been accepted to the Levinthal Fellows which is an exclusive group of 20 individuals at BYU where we will be taught by Mike Levinthal and participate in a summer study abroad in Italy focused on the design and creation of companies. Mike Levinthal is a successful Venture Capital manager in California and Utah.</p>
                                </div>
                            </div>
                            <div className='flex row-start-1 md:col-start-2 justify-center items-center'>
                                <img src='/images/italy.jpg' alt="italy" className='overflow-hidden rounded-xl drop-shadow-2xl'></img>
                            </div>
                        </div>

                        {/* Java */}
                        <div className='grid grid-cols-1 grid-rows-2 md:grid-cols-2 md:grid-rows-1 justify-center items-center'>
                            <div className='flex justify-center items-center'>
                                <img src='/images/java.png' alt="C++ Programming Language" className=''></img>
                            </div>
                            <div className='flex flex-col items-center justify-center p-5'>
                                <div>
                                    <h2 className='text-customblue-main text-lg md:text-xl'>Backend and Mobile</h2>
                                    <h1 className='text-customblue-darkest text-3xl md:text-5xl font-bold'>Java Programming Language</h1>
                                    <p className='text-customblue-darkest max-w-2xl mt-4 text-base md:text-lg'>One project that we worked on is a full stack application in Java. We built a backend Java server that connected to a SQL database. We also built an Android client. We also built various algorithms in Java.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Education
