import React from 'react';
import skills from '../skills-data';
import Card from './Card';


function SkillCards() {
    let allCards = [];
    for (let index = 0; index < skills.length; index++) {
        allCards.push(<Card name={skills[index].name} image={skills[index].image} description={skills[index].description} link={skills[index].link} />);
    }

    return (
        <>
            <div className='flex 3xl:justify-center 3xl:items-center overflow-y-hidden overflow-x-auto'>
                {allCards}
            </div>
        </>
    )
}

export default SkillCards
