let technologies = [
    {
        name: "Next.js",
        image: "nextjs-logo.png",
        link: "https://nextjs.org/",
        description: "Next and Vercel have become my new favorite tools when it comes to production-grade React applications. I have built a couple of websites using Next.js and Vercel and I have been very pleased with the performance and features that Next.js offers.",
    },
    {
        name: "PostgreSQL",
        image: "postgresql-logo.png",
        link: "",
        description: "I have and currently used Postgres at many of the companies that I have worked at. I am very familiar with architecting the relational database to work with the rest of the data as a whole. I am also familiar with RLS and building policies around that.",
    },
    {
        name: "Supabase",
        image: "supabase.png",
        link: "https://supabase.com/",
        description: "I have used Supabase in multiple production environments and it makes for an excellent backend solution. I have also built off of the open-source solution to meet specific needs of our applications. It scales well and I am a huge advocate for the platform.",
    },
    {
        name: "Tailwind CSS",
        image: "tailwind-logo.png",
        link: "https://tailwindcss.com/",
        description: "Tailwind is one of my favorite utility-based CSS libraries. I love making it a part of my stack because it means that I can easily make the design consistent across the entire web application. It also speeds up development time and shortens time to launch.",
    },
    {
        name: "React / React Native",
        image: "react.png",
        link: "https://reactjs.org/",
        description: "I have had a lot of exposure and experience working on a wide variety of projects with React and React Native. I was on a team, building a mobile app using React Native and I have built many apps using React! This website itself is using React.",
    },
    {
        name: "AWS",
        image: "aws-logo.png",
        link: "https://aws.amazon.com/",
        description: "I have had exposure to many of AWS's products and services. I have hosted many applications on AWS with DynamoDB, Lambda, S3, and EC2. It is something that I am constantly looking to improve on and I am very excited to see what the future holds.",
    },
    {
        name: "Docker",
        image: "docker-logo.png",
        link: "https://www.docker.com/",
        description: "I use docker every day to run our different applications locally and get them ready to deploy to staging and production environments. I am familiar with multiple aspects of Docker such as containers, applications, images, volumes, and more.",
    },
    {
        name: "Ruby on Rails",
        image: "rails-logo.png",
        link: "https://rubyonrails.org/",
        description: "A lot of my work at Neighbor is spent on our Ruby on Rails monolith. Rails has been an awesome framework to build out all of our major services. We are pairing that with go microservices to make a more robust platform that can scale better.",
    },
    {
        name: "Firebase",
        image: "firebase-logo.png",
        link: "https://firebase.google.com/",
        description: "Firebase is one of my favorite tools when it comes to hosting mobile and web applications. I am hosting over a dozen separate web and mobile applications using Firebase’s hosting, authentication, serverless functions, and database services.",
    },
    {
        name: "GitHub",
        image: "github-logo.png",
        link: "https://github.com/",
        description: "I use GitHub every day, for every project that I work on, for every company that I work for. I am very familiar with all of the features of Git and GitHub. Everything from the basics of Git to the various features of GitHub such as Actions, Issues, Projects, and more.",
    },
    {
        name: "Vue",
        image: "vue-logo.png",
        link: "https://vuejs.org/",
        description: "I took a class where we learned Vue.js. I have built many different projects using Vue.js. I prefer React over Vue because I think more companies are moving towards React, but I am proficient in Vue.js and can tackle most projects using this framework.",
    },
    {
        name: "Figma",
        image: "figma-logo.png",
        link: "https://www.figma.com/",
        description: "I am very familiar with Figma and other design software. I have had experience with designing multiple websites, apps, and other systems that users interact with. I can design and prototype ideas quickly and also adhere closely to designs when I am developing.",
    },
    {
        name: "Webflow",
        image: "webflow-logo.png",
        link: "https://webflow.com/",
        description: "I am very well versed in building websites with Webflow. It makes it easy, fast, and cheap to throw up websites for many different use cases. It has been a great asset in my toolbelt to accomplish business goals quickly. I can show you many examples upon request.",
    },
    {
        name: "Stripe",
        image: "stripe-logo.png",
        link: "https://stripe.com/",
        description: "Stripe has been one of the most useful tools that I have learned. Monetization is key to any product and Stripe makes that process seamless. I’ve used it in multiple projects to manage subscriptions, send B2B invoices, manage payouts, and more.",
    },
    {
        name: "Twilio + Sendgrid",
        image: "twilio-sendgrid-logo.png",
        link: "https://stripe.com/",
        description: "I have used Twilio in Sendgrid in almost all of my projects. It's a must when it comes to communicating with customers. I also have experience with using phone authentication with Twilio to make a super speedy way for users to log in on mobile. ",
    },
]

export default technologies;