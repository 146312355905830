let skills = [
    {
        name: "Rust",
        image: "rust-logo.png",
        link: "https://www.rust-lang.org/",
        description: "Rust is a language that I have been investing a lot of time learning and building things with. It has one of the highest performing programming languages and it is endorsed by the biggest companies in the world including Google, Meta, and Amazon.",
    },
    {
        name: "TypeScript",
        image: "typescript-logo.png",
        link: "https://www.typescriptlang.org/",
        description: "TypeScript is one of my favorite languages. The flexibility to build anything with the combination of it being statically typed is the best. I have used it to build web applications, mobile applications, and backend systems in almost all of my projects.",
    },
    {
        name: "Golang",
        image: "golang-logo.png",
        link: "https://go.dev/",
        description: "I use Golang at Neighbor to build microservice API endpoints for our various services. I use Docker to bundle and deploy to AWS to handle millions of users. Golang is my second favorite language and I love building backend applications with this language.",
    },
    {
        name: "SQL",
        image: "sql-logo.png",
        link: "https://www.postgresql.org/",
        description: "I have learned SQL from school, work, and online resources. I have used SQL in many school projects, work projects, and personal projects. I am most familiar with SQLite, CockroachDB, and PostgreSQL. I have used Postgres in many production environments.",
    },
    {
        name: "Ruby",
        image: "ruby-logo.png",
        link: "https://www.ruby-lang.org/en/",
        description: "I use Ruby at Neighbor to build APIs for our various services on the platform for our renters. I spend about 30% of my time working with Ruby with Rails. I have built various endpoints from search to payments. Ruby makes it fast to build application back ends.",
    },
    {
        name: "Java",
        image: "java-logo.png",
        link: "https://en.wikipedia.org/wiki/Java_(programming_language)",
        description: "In my classes at BYU we built all sorts of backend applications as well as a native Android mobile app. This taught me a lot about how to manage a large application. The Java language has been a great backend language tool that I have learned.",
    },
    {
        name: "Python",
        image: "python-logo.png",
        link: "https://www.python.org/",
        description: "Python is the first language that I learned when I was a kid and is one that I use a lot for data manipulation and analytics. I am familiar with Anaconda and libraries such as NumPy, Pandas, SciPy, and more. I am learning more using platforms such as Kaggle.",
    },
    {
        name: "C++",
        image: "cpp-logo.png",
        link: "https://www.cplusplus.com/",
        description: "C++ is one of the languages that I am most familiar with. Most of my classes have used C++ to build a variety of things such as an interpreter and many different data structures and algorithms. I am also familiar with IDEs and robust debugging environments.",
    },
    {
        name: "JavaScript",
        image: "javascript-logo@0.5x.png",
        link: "https://www.javascript.com/",
        description: "I am very familiar with JavaScript and all of the associated tools such as Node.js and others. I used JavaScript for frontend code in React and Vue.js as well as backend code. I am familiar with serverless functions on many different platforms",
    },
    {
        name: "HTML & CSS",
        image: "html-and-css-logo.png",
        link: "https://www.w3schools.com/html/default.asp",
        description: "CSS and HTML are classics. I am familiar with all sorts of add-ons such as tailwind CSS, bootstrap, and more. I am also familiar with additional technologies such as Stripe, Plaid, Twillio, SendGrid, and more. I am very familiar with CSS and HTML and can do most things.",
    },
    {
        name: "C",
        image: "c-logo.png",
        link: "https://en.wikipedia.org/wiki/C_(programming_language)",
        description: "C is a language that I learned early on. In my classes, we have built very high-performant applications in the area of image manipulation, operating systems, and more. We also learned x86 and y86 architecture and learned more low-level computer concepts.",
    },
    {
        name: "Bash",
        image: "bash-logo.png",
        link: "https://www.gnu.org/software/bash/",
        description: "I feel right at home on the command line. I can do just about anything and everything with a computer right from the terminal including more complex tasks. It's one of the first things I learned as I was programming and I have been using every aspect of it since.",
    },
]

export default skills;
