import React from 'react'
import Button from './Button'

function Experience() {
    return (
        <>
            <section id='experience'>
                {/* Title */}
                <div className='flex flex-col items-center justify-center bg-customblue-darkest'>
                    <h1 className='text-customblue-lightest text-3xl md:text-5xl font-bold py-10'>My Experience</h1>
                </div>

                <div className='p-4 flex flex-col justify-center items-center'>
                    <div className='max-w-7xl'>
                    {   /* Neighbor */}
                        <div className='grid grid-cols-1 grid-rows-2 md:grid-cols-2 md:grid-rows-1 justify-center items-center'>
                            <div className='flex flex-col items-center justify-center p-5'>
                                <div>
                                    <h2 className='text-customblue-main text-lg md:text-xl'>Software Engineer</h2>
                                    <h1 className='text-customblue-darkest text-3xl md:text-5xl font-bold'>Relay</h1>
                                    <p className='text-customblue-darkest max-w-2xl mt-4 text-base md:text-lg mb-3'>I am building out their entire churn and retention tools for internal use. 95% monthy retention results in a 54% annual retention. If that number drops by only 5% to 90% monthly retention, annual retention drops to 28%. That will kill a company.
                                        <br></br><br></br>I am using Next.js on Vercel to host the admin dashboard and using various backend and caching strategies to gather the data.</p>
                                    <Button text='Learn more' to='https://www.joinrelay.app/' target='_blank' />
                                </div>
                            </div>
                            <div className='flex row-start-1 md:col-start-2 justify-center items-center'>
                                <img src='/images/relay.jpeg' alt="Relay" className=''></img>
                            </div>
                        </div>

                        {/* Stealth Startup */}
                        <div className='grid grid-cols-1 grid-rows-2 md:grid-cols-2 md:grid-rows-1 justify-center items-center'>
                            <div className='flex justify-center items-center'>
                                <img src='/images/coding.gif' alt="Stealth Startup" className=''></img>
                            </div>
                            <div className='flex flex-col items-center justify-center p-5'>
                                <div>
                                    <h2 className='text-customblue-main text-lg md:text-xl'>Software Engineer / Co-Founder</h2>
                                    <h1 className='text-customblue-darkest text-3xl md:text-5xl font-bold'>Stealth Startup</h1>
                                    <p className='text-customblue-darkest max-w-2xl mt-4 text-base md:text-lg mb-3'>Stay tuned. We are working on something pretty awesome.</p>
                                    {/* <Button text='Learn more' to='https://www.sluffapp.com' target='_blank' /> */}
                                </div>
                            </div>
                        </div>

                        {/* Neighbor */}
                        <div className='grid grid-cols-1 grid-rows-2 md:grid-cols-2 md:grid-rows-1 justify-center items-center'>
                            <div className='flex flex-col items-center justify-center p-5'>
                                <div>
                                    <h2 className='text-customblue-main text-lg md:text-xl'>Software Engineer Intern</h2>
                                    <h1 className='text-customblue-darkest text-3xl md:text-5xl font-bold'>Neighbor</h1>
                                    <p className='text-customblue-darkest max-w-2xl mt-4 text-base md:text-lg mb-3'>I work with three main technologies: Go microsevices, Ruby on Rails monolith, and React with Typescript. I use other technologies such as Docker, AWS, ElasticSearch, and others. I work on the Residential Team which covers the entire core product of the business.
                                        <br></br><br></br>I love Neighbor and the people that I work with. I work one-on-one with a mentor that teaches me about the product, technologies, and processes that we use. I have grown more at this company than anything else that I have done. Neighbor is going to be one of the biggest marketplaces in the world.</p>
                                    <Button text='Learn more' to='https://www.neighbor.com' target='_blank' />
                                </div>
                            </div>
                            <div className='flex row-start-1 md:col-start-2 justify-center items-center'>
                                <img src='/images/neighbor.jpeg' alt="Monto Consulting" className=''></img>
                            </div>
                        </div>

                        {/* Sluff */}
                        <div className='grid grid-cols-1 grid-rows-2 md:grid-cols-2 md:grid-rows-1 justify-center items-center'>
                            <div className='flex justify-center items-center'>
                                <img src='/images/sluff.png' alt="Sluff" className=''></img>
                            </div>
                            <div className='flex flex-col items-center justify-center p-5'>
                                <div>
                                    <h2 className='text-customblue-main text-lg md:text-xl'>Full Stack Engineer</h2>
                                    <h1 className='text-customblue-darkest text-3xl md:text-5xl font-bold'>Sluff</h1>
                                    <p className='text-customblue-darkest max-w-2xl mt-4 text-base md:text-lg mb-3'>As a full stack engineer, I use React Native, Supabase, Postgress, Twillio, SendGrid, and more technologies as I work on a team to build the MVP for Sluff. Sluff is a new social media app that helps friends find fun things to do together. Most social media is about documenting your social life, Sluff helps you create a social life.
                                        <br></br><br></br>I have learned a tremendous amount working with experienced engineers in a production grade environment. I’ve learned even more about React Native, Postgres, and all of the other technologies because of this experience.</p>
                                    <Button text='Learn more' to='https://www.sluffapp.com' target='_blank' />
                                </div>
                            </div>
                        </div>

                        {/* Monto Consulting */}
                        <div className='grid grid-cols-1 grid-rows-2 md:grid-cols-2 md:grid-rows-1 justify-center items-center'>
                            <div className='flex flex-col items-center justify-center p-5'>
                                <div>
                                    <h2 className='text-customblue-main text-lg md:text-xl'>Full Stack Engineer/Owner</h2>
                                    <h1 className='text-customblue-darkest text-3xl md:text-5xl font-bold'>Monto Consulting</h1>
                                    <p className='text-customblue-darkest max-w-2xl mt-4 text-base md:text-lg mb-3'>I lead a team of software engineers, designers, and product managers in a small business that I created called Monto Consulting. We build websites, mobile apps, and web apps.
                                        <br></br><br></br>I started Monto Consulting as a way to make money, learn new skills, and get real world experience working on cool projects. I have learned a lot about building production level applicaitons and working with a team. Check out the website to learn more.</p>
                                    <Button text='Learn more' to='https://www.montoconsulting.com' target='_blank' />
                                </div>
                            </div>
                            <div className='flex row-start-1 md:col-start-2 justify-center items-center'>
                                <img src='/images/code.gif' alt="Monto Consulting" className=''></img>
                            </div>
                        </div>

                        {/* Sumsion Business Law */}
                        <div className='grid grid-cols-1 grid-rows-2 md:grid-cols-2 md:grid-rows-1 justify-center items-center'>
                            <div className='flex justify-center items-center'>
                                <img src='/images/sbl.png' alt="Sumsion Business Law" className=''></img>
                            </div>
                            <div className='flex flex-col items-center justify-center p-5'>
                                <div>
                                    <h2 className='text-customblue-main text-lg md:text-xl'>Director of Technology</h2>
                                    <h1 className='text-customblue-darkest text-3xl md:text-5xl font-bold'>Sumsion Business Law</h1>
                                    <p className='text-customblue-darkest max-w-2xl mt-4 text-base md:text-lg mb-3'>As the Director of Technology, I lead a team of 5 to complete various tasks throughout the company. Everything from building and maintaining the website, to implementing cybersecurity measures to keep our client information safe, and agregating our data from all of our sources to make more informed decisions. I find ways that to help the company grow and make proactive efforts to implement those solutions.
                                        <br></br><br></br>I have used many technologies at the firm such as React, Linux, Nginx, Stripe, Plaid, Python, Google Cloud, and worked with hardware such as Ubiquiti, Dell EMC, and more.</p>
                                    <Button text='Learn more' to='https://www.businesslawutah.com' target='_blank' />
                                </div>
                            </div>
                        </div>

                        {/* Global Education Allies */}
                        <div className='grid grid-cols-1 grid-rows-2 md:grid-cols-2 md:grid-rows-1 justify-center items-center'>
                            <div className='flex flex-col items-center justify-center p-5'>
                                <div>
                                    <h2 className='text-customblue-main text-lg md:text-xl'>Consultant</h2>
                                    <h1 className='text-customblue-darkest text-3xl md:text-5xl font-bold'>Global Education Allies</h1>
                                    <p className='text-customblue-darkest max-w-2xl mt-4 text-base md:text-lg mb-3'>Me and a team are in the process of building a production grade web application using React and Firebase, along with tools such as Stripe and SendGrid, for an international education company. Their business helps teachers teach better by taking them on educational trips to show them new ways of teaching from other countries and cultures.
                                        <br></br><br></br>We initially created a backend service to handle their clients program applications and payment plans but quickly needed to scale it to an enterprise level solution.</p>
                                    <Button text='Learn more' to='https://www.globaleducationallies.com' target='_blank' />
                                </div>
                            </div>
                            <div className='flex row-start-1 md:col-start-2 justify-center items-center'>
                                <img src='/images/gea.png' alt="Global Education Allies" className=''></img>
                            </div>
                        </div>

                        {/* The Swell Creative */}
                        <div className='grid grid-cols-1 grid-rows-2 md:grid-cols-2 md:grid-rows-1 justify-center items-center'>
                            <div className='flex justify-center items-center'>
                                <img src='/images/the-swell-pod.png' alt="The Swell Pod" className=''></img>
                            </div>
                            <div className='flex flex-col items-center justify-center p-5'>
                                <div>
                                    <h2 className='text-customblue-main text-lg md:text-xl'>Consultant</h2>
                                    <h1 className='text-customblue-darkest text-3xl md:text-5xl font-bold'>The Swell Creative</h1>
                                    <p className='text-customblue-darkest max-w-2xl mt-4 text-base md:text-lg mb-3'>I led a team of 5 to design and develop a website in a very quick timeframe. We used Figma, Adobe Illustrator, Adobe After Effects, and Adobe Priemere to design the website and assets that we would use for the website.
                                        <br></br><br></br>We were then able to develop the website before the podcast was launched and we were able to advertise and drive traffic to the podcast platforms. The Swell Pod is a business podcast where they interview movers and shakers that start an idea that swells into a movement.</p>
                                    <Button text='Learn more' to='https://theswellpod.com' target='_blank' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Experience
