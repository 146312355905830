import Navbar from './components/Navbar-new';
import Footer from './components/Footer';
import Home from './pages/Home';
import ScrollToTop from './components/ScrollToTop';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ExperiencePage from './pages/ExperiencePage';
import EducationPage from './pages/EducationPage';


function App() {
  return (
    <>
      <Router>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path='/' exact element={<Home />} />
          <Route path='/experience' element={<ExperiencePage />} />
          <Route path='/education' element={<EducationPage />} />
        </Routes>
        <Footer />

      </Router>
    </>
  );
}

export default App;
