import React from 'react';
import technologies from '../technologies-data';
import Card from './Card';

function TechnologyCards() {
    let allCards = [];
    for (let index = 0; index < technologies.length; index++) {
        allCards.push(<Card name={technologies[index].name} image={technologies[index].image} description={technologies[index].description} link={technologies[index].link} />);
    }

    return (
        <>
            <div className='flex 3xl:justify-center 3xl:items-center overflow-y-hidden overflow-x-auto'>
                {allCards}
            </div>
        </>
    )
}

export default TechnologyCards
