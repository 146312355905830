import React from 'react'

function Footer() {
    return (
        <>
            <div className='flex justify-center items-center bg-customblue-darkest p-8 text-white text-5xl'>
                <a target='_blank' rel="noreferrer" href='https://www.linkedin.com/in/spencerbart/'>
                    <i class="fab fa-linkedin-in p-3"></i>
                </a>
                <a target='_blank' rel="noreferrer" href='https://github.com/konga400/spencer-bartholomew'>
                    <i class="fab fa-github p-3"></i>
                </a>
            </div>
        </>
    )
}

export default Footer
