import React from 'react'

function Button({
    text,
    to,
    target = '_self',
}) {
    return (
        <>
            <a href={to} target={target} rel="noreferrer">
                <div className='group hover:bg-white rounded-xl shadow-md px-4 py-3 bg-customblue-darkest w-max'>
                    <h3 className='group-hover:text-customblue-main text-white'>{text}</h3>
                </div>
            </a>
        </>
    )
}

export default Button
