import React from 'react'
// import Card from './Card'
import SkillCards from './SkillCards'
import TechnologyCards from './TechnologyCards'

function Skills() {
    return (
        <>
            <section id='skills'>
                {/* Title */}
                <div className='flex flex-col items-center justify-center bg-customblue-darkest'>
                    <h1 className='text-customblue-lightest text-3xl md:text-5xl font-bold py-10'>My Skills</h1>
                </div>

                <h2 className='font-semibold m-6 text-3xl'>Technologies</h2>
                <TechnologyCards />
                <h2 className='font-semibold m-6 text-3xl'>Languages</h2>
                <SkillCards />
            </section>
        </>
    )
}

export default Skills
