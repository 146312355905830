import React from 'react'
import Hero from '../components/Hero';
import Skills from '../components/Skills';
import Experience from '../components/Experience';
import Education from '../components/Education';
import Form from '../components/Form';

function Home() {
    return (
        <>
            <Hero />
            <Skills />
            <Experience />
            <Education />
            <Form />
        </>
    )
}

export default Home
