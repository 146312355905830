import React from 'react'
import Education from '../components/Education'

function EducationPage() {
    return (
        <>
            <div className='p-8'></div>
            <Education />
        </>
    )
}

export default EducationPage
