import React from 'react'

function Card({
    image,
    name,
    description,
    link,
}) {
    return (
        <>
            <a href={link} target="_blank" rel="noreferrer">
                <div className='rounded-xl w-64 min-w-min bg-customblue-darkest p-4 mx-5 mt-0 mb-10 drop-shadow-xl shadow-2xl'>
                    {/* <div className='h-28 bg-customblue-main rounded-3xl flex items-center p-4 -mx-4 -mt-4 mb-4'> */}
                    <div className='h-28 bg-blue-100 rounded-xl flex items-center p-4 -mx-4 -mt-4 mb-4'>
                        <img src={`/images/${image}`} alt={name} className='h-20'></img>
                        {/* <h3 className='text-customblue-lightest text-xl font-bold ml-2'>{name}</h3> */}
                        <h3 className='text-gray-600 text-xl font-bold ml-2'>{name}</h3>
                    </div>
                    <p className='text-customblue-lightest w-64'>{description}</p>
                </div>
            </a>
        </>
    )
}

export default Card