import React from 'react';
// import Button from './Button';

function Hero() {
    return (
        <>
            <section>
                <div className='grid grid-rows-2 grid-cols-1 md:grid-rows-1 md:grid-cols-2 min-w-full h-screen'>
                    <div className='flex flex-col items-center justify-center p-5'>
                        <div>
                            <h2 className='text-customblue-main text-lg md:text-xl'>Hello</h2>
                            <h1 className='text-customblue-darkest text-3xl md:text-5xl font-bold'>My name is<br></br>Spencer Bartholomew</h1>
                            <p className='text-customblue-darkest max-w-2xl mt-4 text-base md:text-lg'>I am a Computer Science student at BYU and my goal is to help make this world a better place through technology</p>
                            {/* <Button></Button> */}
                        </div>
                    </div>
                    <div className='relative flex flex-col items-center justify-end row-start-1 row-end-1 md:col-start-2 overflow-hidden'>
                        <img src='/images/spencer.png' alt='Spencer' className='h-4/5 object-cover z-10 absolute'></img>
                        <div className='bg-customblue-main rounded-tl-3xl h-5/6 w-3/4 bottom-0 right-0 z-0 mt-24 absolute'></div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Hero
